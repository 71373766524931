@import '../../../../styles/customMediaQueries.css';

.articleMain {
  /* 720px + (2 * 32px) == 784 */
  max-width: 784px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 32px;
  margin: 0 auto;
  padding: 0 32px;
}

.noSidePaddings {
  padding-left: 0;
  padding-right: 0;
}

.blockContainerScroll {
  @media (--viewportLarge) {
    max-height: calc(80vh - 110px - 11px - 132px) !important;
  }
  padding: 0px 32px 0 32px !important;
  overflow: auto;
  max-height: calc(100vh - 72px - 110px - 6px - 64px);
  margin-top: 32px;
  scrollbar-width: thin;
  &:first-child {
    padding: 0 32px;
  }
  
}
